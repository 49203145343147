<template>
    <div style="display: none;" class="mbb-placeholder"/>
</template>

<script setup>
import { onMounted } from 'vue';
import api from '../api.js';
import { computed } from 'vue';

window.MBB = { seo: "false", data: { acid: "" }};

const isEnabled = computed(() => {
    return window.MBB.data.acid && window.MBB.data.acid.trim() !== '';
});

window.mbbMapLoaded = function() {
    window.MBB.googleMaps = true;

    // Load Buying Buddy after Google Maps is loaded
    let script = document.createElement('script');
    script.id = 'bbjs';
    script.src = 'https://d2w6u17ngtanmy.cloudfront.net/scripts/my-buying-buddy.5.0.js.gz';
    document.head.appendChild(script);
};

onMounted(() => {
    if (!window.bb_initialized) {
        api.getSettings((settings) => {
            window.MBB.data.acid = settings.mbb_acid;

            if (!isEnabled.value) {
                return;
            }
            // Load Buying Buddy CSS
            let script = document.createElement('script');
            script.src = `https://www.mbb2.com/version3/css/theme/acid/${settings.mbb_acid}`;
            document.head.appendChild(script);

            // Load Google Maps
            script = document.createElement('script');
            script.async = true;
            script.src = `https://maps.googleapis.com/maps/api/js?callback=mbbMapLoaded&loading=async&libraries=places,marker&key=${settings.google_maps_api_key}`;
            document.head.appendChild(script);
            window.bb_initialized = true;
        });
    }
});
</script>
