<template>
  <div id="app" :style="{ zoom: isKioskMode ? '1.1' : '1' }">
    <header>
      <a href="/" v-html="header" @click="closeNav"></a>
      <nav class="navbar navbar-expand-lg" v-if="!isKioskMode">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item"><a href="/about" class="nav-link" @click="closeNav">{{ strings.About }}</a></li>
            <li class="nav-item"><a href="/contact" class="nav-link" @click="closeNav">{{ strings.Contact }}</a></li>
            <li class="nav-item" v-if="BuyingBuddy.isEnabled"><a href="/homes" class="nav-link" @click="closeNav">{{ strings.HomeFinder }}</a></li>
            <li class="nav-item"><a href="/admin/content" class="nav-link" @click="closeNav">{{ strings.Admin }}</a></li>
            <li class="nav-item"><a href="/snake" class="nav-link" @click="closeNav">{{ strings.Snake }}</a></li>
            <li class="nav-item" v-if="isAuthenticated"><a class="nav-link" href="#" @click="logout">{{ strings.Logout }}</a></li>
          </ul>
        </div>
      </nav>
    </header>
    <main>
      <RouterView />
      <BuyingBuddy />
    </main>
    <footer v-if="!isKioskMode">
      <div v-html="footer"></div>
    </footer>
  </div>
</template>

<script setup>
import { strings } from './strings.js';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import api from './api.js';
import BuyingBuddy from './components/BuyingBuddy.vue';

const isKioskMode = ref(window.isKioskMode);

const header = ref('');
const footer = ref('');
const pageName = ref('');

const router = useRouter();
const isAuthenticated = ref(api.isAuthenticated());

onMounted(() => {
  document.title = strings.Title;
  api.fetchContent('header', (response) => { header.value = response; });
  api.fetchContent('footer', (response) => { footer.value = response; });

  router.afterEach((to, from) => {
    if (to.name !== from.name) {
      isAuthenticated.value = api.isAuthenticated();
      pageName.value = to.name;
    }
  });
});

const logout = () => {
  localStorage.removeItem('token');
  isAuthenticated.value = false;
  router.push('/');
};

const closeNav = () => {
  const navbarCollapse = document.getElementById('navbarSupportedContent');
  if (navbarCollapse.classList.contains('show')) {
    navbarCollapse.classList.remove('show');
  }
};
</script>
